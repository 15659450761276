@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
    font-family: "Poppins";
    overflow-x: hidden;
    background-color: #fff;
    margin: 0;
}
.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}
.icon{
    max-width: 17px;
    margin-right: 8px;
}
a{
    text-decoration: none;
}
.titulo h1{
    font-size: 2.5rem;
}
.economize{
    color: rgba(3, 38, 156, 0.822);
}
.conta{
    color: rgba(3, 38, 156, 0.822);
}
 
.whatsapp-link{
    position: fixed;
    width: 276px;
    height: 73px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 3px #888;
    z-index: 1000;
}
