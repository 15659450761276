.rodape{
    width: 100%;
    height: 150px;
    background-image: url("../../assets/bg-rodape.png");
    background-size: contain;
    background-repeat: repeat-x;
}


.menu-rodape{
    float: right;
    padding: 10px;
}

.menu-rodape a{
    color: #000000;
    margin-right: 25px;
}

.menu-rodape a:hover{
    color: #e24304;
}