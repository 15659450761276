.navbar{
    width: auto;
    text-align: center;
    max-width: 100%;
}

.row{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    background-color: #e24304;
    

}
.btn{
    margin: 0 3% 5% 0;

}

.banner img{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

}
